import React from 'react';
import PropTypes from 'prop-types';
import { Result } from 'antd';

const ResultMessage = ({ title, status, subTitle, extra }) => {
  return (
    <Result
      className={`result-message ${status}`}
      title={title}
      status={status}
      subTitle={subTitle}
      extra={extra}
    />
  );
};

ResultMessage.propTypes = {
  title: PropTypes.node,
  status: PropTypes.string,
  subTitle: PropTypes.node,
  extra: PropTypes.node
};
ResultMessage.defaultProps = {
  title: undefined,
  status: 'info',
  subTitle: undefined,
  extra: undefined
};

export default ResultMessage;
